import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import "./App.css";
import Home from "./components/home/Home";
import Documents from "./components/document/Documents";
import Health from "./components/health/Health";

function App() {
  return (
    <div className="grey lighten-4">
      {/* nav header project */}
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/health" component={Health} />
            <Route path="/home/documents/:hash" component={Documents} /> 
            <Redirect from ='*' to='/' />
          </Switch>
        </div>
      </BrowserRouter> 
    </div>
  );
}

export default App;
