import React, { Component } from "react";
import Footer from "../template/Footer";
import HeaderNavbar from "../template/HeaderNavbar";

class Health extends Component {
  render() {
    return (
      <div>
        <HeaderNavbar />
        <div className="container">
          <section className="section section-recent">
            <div className="row">
              <div className="col s12 l12 m12">
                <div className="card">
                  <div className="card-content">
                    <div className="row">
                      <div className="col s12 l12" style={{marginTop: '100px', marginBottom: '120px'}}>
                        <span className="card-title card-title--gestao">
                            <center>Health check</center>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Health;
