import React, { Component } from "react";
import Footer from "../template/Footer";
import HeaderNavbar from "../template/HeaderNavbar";
import DisclaimerMessage from "vidalink-disclaimer-message";

class Home extends Component {

  constructor(props) {
    super(props) 
    this.state = {
      //message: 'Todos os dados coletados são confidenciais. Após o download do(s) arquivo(s), a distribuição dessas informações é de sua responsabilidade.'    
    }
    //this.copyToClipboard = this.copyToClipboard.bind(this)
  
  }

  // copyToClipboard(e) {
  //   // Create a "hidden" input
  //   var aux = document.createElement("input");
  //   // Assign it the value of the specified element
  //   aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
  //   // Append it to the body
  //   document.body.appendChild(aux);
  //   // Highlight its content
  //   aux.select();
  //   // Copy the highlighted text
  //   document.execCommand("copy");
  //   // Remove it from the body
  //   document.body.removeChild(aux);
  //   //alert("Print screen desabilitado."); 
  // }
  

  render() {

    // setInterval(function () {
    //   // this.copyToClipboard();
    //   // Create a "hidden" input
    //   var aux = document.createElement("input");
    //   // Assign it the value of the specified element
    //   aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
    //   // Append it to the body
    //   document.body.appendChild(aux);
    //   // Highlight its content
    //   aux.select();
    //   // Copy the highlighted text
    //   document.execCommand("copy");
    //   // Remove it from the body
    //   document.body.removeChild(aux);
    //   //alert("Print screen desabilitado.");
    //   }, 500);

    // window.addEventListener("keyup", function (e) {
    //   if (e.keyCode == 44)  {
    //       //this.copyToClipboard(e);
    //       // Create a "hidden" input
    //       var aux = document.createElement("input");
    //       // Assign it the value of the specified element
    //       aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
    //       // Append it to the body
    //       document.body.appendChild(aux);
    //       // Highlight its content
    //       aux.select();
    //       // Copy the highlighted text
    //       document.execCommand("copy");
    //       // Remove it from the body
    //       document.body.removeChild(aux);
    //       alert('Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.')
    //   }
    // });

    // window.addEventListener("focus", function (e) {
    //   document.getElementsByTagName("body")[0].style.display = 'block'
    // });

    // window.addEventListener("blur", function (e) {
    //   document.getElementsByTagName("body")[0].style.display = 'none'
    // });

    return (
      <div>
        <HeaderNavbar />
        <DisclaimerMessage msg={this.state.message} />
        <div className="container">
          
          <section className="section section-recent">
            <div className="row">
              <div className="col s12 l12 m12">
                <div className="card">
                  <div className="card-content">
                    <div className="row">
                      <div className="col s12 l12" style={{marginTop: '100px', marginBottom: '120px'}}>
                        <span className="card-title card-title--gestao">
                            <center>Sessão expirada</center>
                        </span>
                        <p align="center">
                          Faça novamente o login no Portal Empresas.<br></br>
                          <a href="http://www.vidalink.com.br/portalempresas">clique aqui</a>
                        </p>

                        <h5></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
