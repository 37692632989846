import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from '../login/AuthService';

class DonwloadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError:false,
      fileId: this.props.fileId
    };
  }

  fetchDownloads() {
    this.setState({ isLoading: true });
    const auth = new Auth();
    auth.getAcessToken(sessionStorage.getItem('hash'), (token)=>{
      if(!token)  this.props.history.push('/home');
      const baseUrl = 'https://empresas-middleware.vidalink.com.br';
      fetch(`${baseUrl}/api/document/donwload/base64/${this.state.fileId}`, {
        method: "GET",
        headers: {
          "Cache-Control":"max-age=0, no-cache, no-store, must-revalidate",
          "x-access-token" :token,
        }
      })
      .then(response => response.json())
      .then(resp => {
        let error = false;
        if(resp.status){
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:${resp.data.contentType};base64,${resp.data.data}`;
          downloadLink.download = resp.data.fileName;
          downloadLink.click();
        }else{
          error = true;
        }

        this.setState({ isLoading: false,
                        isError: error });
      });
    });
  }

  render() {
    return this.state.isLoading ? (
      
      <div className="waves-effect waves-light z-depth-2 button-Down" style={{width :"26%"}}>
        <div className="preloader-wrapper small active">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle" />
            </div>
            <div className="gap-patch">
              <div className="circle" />
            </div>
            <div className="circle-clipper right">
              <div className="circle" />
            </div>
          </div>
        </div>
        
      </div>
    ) : (
      
      <Link
        to={() => {}}
        onClick={() => {
          this.fetchDownloads(); }}>

        { this.state.isError ? <i className="material-icons button-Down">error</i> : <i className="material-icons button-Down">get_app</i> }
        { this.state.isError ? '' : ''}
      </Link>

    );
  }
}

export default DonwloadButton;
