import React from "react";
// import logo from "https://img.vidalink.com.br/shared/logo_vdk.svg";
import { Navbar } from "react-materialize";
import { Link } from "react-router-dom";

export default props => (
  <div className="header">
  <div className="container">
  <Navbar
    className="navbar"
    brand={<Link to="" />}
    alignLinks="left"
    sidenav={<li />}
  >
    
  <Link to="/" className="brand-logo">
  </Link>
    
    {/* <SideNav /> */}
  </Navbar>

  </div>
  </div>
);
