import React, { Component } from "react";
import FiltrosDownload from "./FiltrosDownload";
import ListaDownload from "../ListaDownload";
import DisclaimerMessage from "vidalink-disclaimer-message";

class Section extends Component {

  constructor(props) {
    super(props)

    let now = new Date();

    this.state = {
      anoDeInicio: now.getFullYear(),
      anoDeFim: now.getFullYear(),
      mesDeInicio: (now.getMonth()+1),
      mesDeFim: (now.getMonth()+1),
      docState: "",
      typeDoc: [],
      clientCnpj: [],
      message: 'Todos os dados coletados são confidenciais. Após o download do(s) arquivo(s), a distribuição dessas informações é de sua responsabilidade.'
    }

    this.setAnoDeInicio = this.setAnoDeInicio.bind(this)
    this.setAnoDeFim = this.setAnoDeFim.bind(this)
    this.setMesDeInicio = this.setMesDeInicio.bind(this)
    this.setMesDeFim = this.setMesDeFim.bind(this)
    this.setStatus = this.setStatus.bind(this)
    this.setTypeDoc = this.setTypeDoc.bind(this)
    this.setClientCnpj = this.setClientCnpj.bind(this)
  }

  setAnoDeInicio(anoDeInicio1) {
    this.setState({anoDeInicio: anoDeInicio1})
  }

  setAnoDeFim(anoDeFim1) {
    this.setState({anoDeFim: anoDeFim1})
  }

  setMesDeInicio(mesDeInicio1) {
    this.setState({mesDeInicio: mesDeInicio1})
  }

  setMesDeFim(mesDeFim1) {
    this.setState({mesDeFim: mesDeFim1})
  }

  setStatus(value){
    this.setState({docState: value})
  }

  setTypeDoc(value){
    this.setState({typeDoc: value});
  }

  setClientCnpj(value){
    let list =[]
    for (const key in value) {
      list.push(value[key].tag)
    }
    this.setState({clientCnpj: list})
  }
  
  render() {
    return (
      <div className="container">
      {/* <DisclaimerMessage msg={this.state.message} /> */}
      <section className="section section-recent">
        <div className="row">
          <div className="col s12 l12 m12">
            <div className="card white-Box">
              <div className="card-content">
                <div className="row">
                  <div className="col s12 l12 card-title--gestao">
                    <span className="card-title">
                      Gestão Financeira
                    </span>
                    <p>
                      Gerencie e faça o download de demonstrativos, notas e
                      boletos
                    </p>
                  </div>
                </div>
                <FiltrosDownload anoDeInicioCallback={this.setAnoDeInicio} anoDeFimCallback={this.setAnoDeFim} mesDeInicioCallback={this.setMesDeInicio} mesDeFimCallback={this.setMesDeFim} statusCallback={this.setStatus} typeDocCallBack={this.setTypeDoc} clientCnpjCallBack={this.setClientCnpj} anoDeInicio={this.state.anoDeInicio} anoDeFim={this.state.anoDeFim} mesDeInicio={this.state.mesDeInicio} mesDeFim={this.state.mesDeFim} />
                <ListaDownload anoDeInicioAction={this.state.anoDeInicio} anoDeFimAction={this.state.anoDeFim} mesDeInicioAction={this.state.mesDeInicio} mesDeFimAction={this.state.mesDeFim} statusAction={this.state.docState} typeDocAction={this.state.typeDoc} clientCnpjAction={this.state.clientCnpj} />
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}

export default Section;
