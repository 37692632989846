import moment from 'moment';

export default class AuthService {
    getAcessToken(token, callback){
        const token_expired = sessionStorage.getItem('token_expired');
       
        if(token_expired === 'undefined' || !token_expired || moment(token_expired).isBefore(moment())){
            this.authToken(token, (result) =>{
                if(result.hasOwnProperty("token")){
                    this.setToken(result);
                    callback(sessionStorage.getItem('id_token'));
                }else{
                    callback('');
                }
            });
        }else{
            callback(sessionStorage.getItem('id_token'));
        }
    }

    authToken(token, callback){
        const baseUrl = 'https://empresas-middleware.vidalink.com.br';
        fetch(`${baseUrl}/api/auth/byhash/${ token }`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Cache-Control":"max-age=0, no-cache, no-store, must-revalidate"
            }
        }).then(resp => resp.json())
        .then((result)=>{
            callback(result);
        })
    }

    setToken(token) {
        sessionStorage.setItem('id_token', token.token);
        sessionStorage.setItem('token_expired', token.token_expired);
    }

    logout() {
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('token_expired');
    }  
}