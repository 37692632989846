import React, { Component } from "react";
import { Checkbox, Collapsible, CollapsibleItem, Icon } from "react-materialize";
import DonwloadButton from "./template/DonwloadButton";
import ReactSnackBar from "react-js-snackbar";
import { Link } from "react-router-dom";
import Auth from './login/AuthService';

const CNPJ = require("cpf_cnpj").CNPJ;

class ListaDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileId: { fileId: null },
      isLoading: true,
      error: null,
      Show: false,
      Showing: false,
      isLoadingPacote: false,
      pacoteDownload: null,
    };
  }

  fetchDocuments(paramAnoIni, paramAnoFim, paramMesInicio, paramMesFim, paramStatus, paramTypeDoc, paramsClientCnpj) {
    let params = {};

    params['year'] = `${paramAnoIni}-${paramAnoFim}`;
    params['month'] = `${paramMesInicio}-${paramMesFim}`;
    if (paramStatus) params['status'] = paramStatus;
    if (paramTypeDoc && paramTypeDoc.length) params['type'] = paramTypeDoc.toString();

    if (paramsClientCnpj && paramsClientCnpj.length >= 0) {
      let client = "";
      let cnpj = "";
      for (const key in paramsClientCnpj) {
        if (CNPJ.isValid(paramsClientCnpj[key])) {
          cnpj += paramsClientCnpj[key] + ",";
        } else {
          client += paramsClientCnpj[key] + ",";
        }
      }
      if (client !== "") params['client'] = client.toUpperCase();
      if (cnpj !== "") params['cnpj'] = cnpj;
    }
    const auth = new Auth();
    auth.getAcessToken(sessionStorage.getItem('hash'), (token) => {
      if (!token) this.props.history.push('/home');
      const baseUrl = 'https://empresas-middleware.vidalink.com.br';
      fetch(
        `${baseUrl}/api/document/list?filter=${JSON.stringify(params)}`,
        {
          method: "GET",
          headers: {
            "Cache-Control": "max-age=0, no-cache, no-store, must-revalidate",
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": token,
          }
        }
      ).then(response => response.json())
        .then(resp => {

          this.setState({
            documents: this.organizingObject(resp.data),
            isLoading: false,
            yearsFilter: [],
            monthsFilter: []
          });
        })
        .catch(error => this.setState({ isLoading: false }));
    });
  }

  organizingObject(data) {
    let obj = {};
    for (const doc in data) {
      if (obj.hasOwnProperty(data[doc].year)) {
        if (obj[data[doc].year].hasOwnProperty(data[doc].monthId)) {
          obj[data[doc].year][data[doc].monthId].push(data[doc]);
        } else {
          obj[data[doc].year][data[doc].monthId] = [];
          obj[data[doc].year][data[doc].monthId].push(data[doc]);
        }
      } else {
        obj[data[doc].year] = {};
        obj[data[doc].year][data[doc].monthId] = [];
        obj[data[doc].year][data[doc].monthId].push(data[doc]);
      }
    }

    return obj;
  }

  componentDidMount() {
    this.fetchDocuments(
      this.props.anoDeInicioAction,
      this.props.anoDeFimAction,
      this.props.mesDeInicioAction,
      this.props.mesDeFimAction
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.anoDeInicioAction !== prevProps.anoDeInicioAction ||
      this.props.anoDeFimAction !== prevProps.anoDeFimAction ||
      this.props.mesDeInicioAction !== prevProps.mesDeInicioAction ||
      this.props.mesDeFimAction !== prevProps.mesDeFimAction ||
      this.props.statusAction !== prevProps.statusAction ||
      this.props.typeDocAction !== prevProps.typeDocAction ||
      this.props.clientCnpjAction !== prevProps.clientCnpjAction
    ) {
      this.fetchDocuments(
        this.props.anoDeInicioAction,
        this.props.anoDeFimAction,
        this.props.mesDeInicioAction,
        this.props.mesDeFimAction,
        this.props.statusAction,
        this.props.typeDocAction,
        this.props.clientCnpjAction
      );
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  listaPacote(i) {
    let pacote = [];
    pacote.push(i);
  }

  checkAll(event) {
    debugger;
    const allCheckboxChecked = document.getElementById("todos");
    let allCheckboxes = document.getElementsByClassName("marcatodos");
    let pacote = [];

    if (allCheckboxChecked === event.target) {
      if (allCheckboxChecked.checked) {
        for (let i in allCheckboxes) {
          if (
            allCheckboxes[i].checked === false ||
            allCheckboxes[i].checked === true
          ) {
            allCheckboxes[i].checked = true;
            pacote.push(allCheckboxes[i].value);
            this.setState({ Show: true, Showing: true });
          }
        }
      } else {
        for (let i in allCheckboxes) {
          if (allCheckboxes[i].checked === true) {
            allCheckboxes[i].checked = false;
            this.setState({ Show: false, Showing: false });
          }
        }
      }
    } else {
      for (let i in allCheckboxes) {
        if (allCheckboxes[i].checked === true)
          pacote.push(allCheckboxes[i].value);
      }
      if (pacote.length === allCheckboxes.length)
        allCheckboxChecked.checked = true;
      else
        allCheckboxChecked.checked = false;
    }
    if (pacote.length.toString() > 0) {
      this.setState({ Show: true, Showing: true })
    } else {
      this.setState({ Show: false, Showing: false })
    }
    this.setState({ pacoteDownload: pacote.toString() });
  }

  fetchDownloadPacote(pacote) {
    this.setState({ isLoadingPacote: true });
    const auth = new Auth();
    auth.getAcessToken(sessionStorage.getItem('hash'), (token) => {
      if (!token) this.props.history.push('/home');
      const baseUrl = 'https://empresas-middleware.vidalink.com.br';
      fetch(`${baseUrl}/api/document/multidonwload/base64/${pacote}`, {
        method: "GET",
        headers: {
          "Cache-Control": "max-age=0, no-cache, no-store, must-revalidate",
          "x-access-token": token,
        }
      })
        .then(response => response.json())
        .then(resp => {
          if (resp.status) {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:${resp.data.contentType};base64,${resp.data.data}`;
            downloadLink.download = resp.data.fileName;
            downloadLink.click();
          }

          this.setState({ isLoadingPacote: false });
          setTimeout(() => {
            this.setState({ Show: false, Showing: false });
          }, 500);
        });
    });
  }

  render() {
    const { isLoading, documents, error } = this.state;
    let collapsibleItemPush = [];

    let anos = !documents
      ? []
      : Object.keys(documents).sort(function (a, b) {
        return b - a;
      });
    for (let y = 0; y < anos.length; y++) {
      const ano = anos[y];
      let meses = !documents[ano]
        ? []
        : Object.keys(documents[ano]).sort(function (a, b) {
          return b - a;
        });
      for (let x = 0; x < meses.length; x++) {
        const mes = meses[x];
        let mesValor;
        switch (mes) {
          case "1":
            mesValor = "Janeiro";
            break;
          case "2":
            mesValor = "Fevereiro";
            break;
          case "3":
            mesValor = "Março";
            break;
          case "4":
            mesValor = "Abril";
            break;
          case "5":
            mesValor = "Maio";
            break;
          case "6":
            mesValor = "Junho";
            break;
          case "7":
            mesValor = "Julho";
            break;
          case "8":
            mesValor = "Agosto";
            break;
          case "9":
            mesValor = "Setembro";
            break;
          case "10":
            mesValor = "Outubro";
            break;
          case "11":
            mesValor = "Novebro";
            break;
          case "12":
            mesValor = "Dezembro";
            break;
          default:
        }

        let children = [];
        for (let itens in documents[ano][mes]) {
          let fileName = documents[ano][mes][itens].name;
          let name = documents[ano][mes][itens].name.split(".");
          let type = documents[ano][mes][itens].typeId;
          let typeName;
          switch (type) {
            case "20":
              typeName = "Boletos";
              break;
            case "40":
              typeName = "Demonstrativos";
              break;
            case "60":
              typeName = "Notas";
              break;
            default:
              typeName = "Outros";
          }
          let fileId = documents[ano][mes][itens].id;
          let status = documents[ano][mes][itens].status;
          let statusName;
          let classFunction;
          switch (status) {
            case "1":
              statusName = "ativo";
              classFunction = " active-Color";
              break;
            default:
              statusName = "cancelado";
              classFunction = " cancel-color";
          }

          children.push(
            <tr key={fileId}>
              <td class="checkbox-column">
                <Checkbox
                  id={fileId}
                  filledIn
                  key={fileId}
                  value={fileId}
                  className="item marcatodos"
                  name="checkmarca"
                  onChange={e => this.checkAll(e)}
                />
                <span />
              </td>
              <td class="name-File" style={{ textTransform: "capitalize" }}>
                {name[0].toLowerCase()}
              </td>
              <td class="document-Type">{typeName}</td>
              <td><span class={"status" + { classFunction }.classFunction} id="StatusId">{statusName}</span></td>
              <td style={{ textAlign: "center" }}>
                <DonwloadButton fileName={fileName} fileId={fileId} />
              </td>
            </tr>
          );
        }

        collapsibleItemPush.push(
          <CollapsibleItem
            key={`s_` + ano + mes}
            header={`COMPETÊNCIA: ` + ano + ` - ` + mesValor}
            icon={<Icon>filter_drama</Icon>}
            expanded
          >
            <form action="#">
              <table className="highlight responsive-table" style={{ borderCollapse: "separate", borderSpacing: "10px" }}>
                <thead>
                  <tr style={{ border: "0px transparent !important" }}>

                    <th className="coluna checkbox"></th>
                    <th className="coluna">Nome do Arquivo</th>
                    <th className="coluna">Tipo do documento</th>
                    <th className="coluna">Status</th>
                    <th className="coluna">Arquivo</th>

                  </tr>
                </thead>
                <tbody>{children}</tbody>
              </table>
            </form>
          </CollapsibleItem>
        );
      }
    }

    return (
      <div>
        {error ? <p>{error.message}</p> : null}
        {!isLoading ? (
          !this.isEmpty(documents) ? (
            <div>
              <div className="row l12">
                <div className="col fit-content l1"style={{ paddingLeft: "47px" }}>
                  <Checkbox
                    id="todos"
                    filledIn
                    key="Marcar todos"
                    value="Marcar todos"
                    className="marcar"
                    name="checkmarca"
                    onChange={e => this.checkAll(e)}
                  />
                </div>
                <div className="col l4"style={{ paddingLeft: "0", color: "rgba(0,0,0,0.87)" }}>
                  <strong>Selecionar todos os arquivos filtrados</strong>
                </div>
              </div>
              <Collapsible accordion={false}>{collapsibleItemPush}</Collapsible>
              <ReactSnackBar Show={this.state.Show}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <div>
                    <p style={{ marginBottom: "8px" }}>
                      Baixe aqui seu pacote de arquivos :)
                    </p>
                  </div>
                  <div>
                    {this.state.isLoadingPacote ? (
                      <div
                        className="waves-effect purple-light waves-light btn-small z-depth-2"
                        style={{ width: "194.016px" }}
                      >
                        <div className="preloader-wrapper small active">
                          <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                              <div className="circle" />
                            </div>
                            <div className="gap-patch">
                              <div className="circle" />
                            </div>
                            <div className="circle-clipper right">
                              <div className="circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={() => { }}
                        onClick={() => {
                          this.fetchDownloadPacote(this.state.pacoteDownload);
                        }}
                        className="waves-effect purple waves-light btn-small z-depth-2"
                      >
                        <i className="material-icons left">get_app</i>
                        download pacote
                      </Link>
                    )}
                  </div>
                </div>
              </ReactSnackBar>
            </div>
          ) : (
            "Sem documentos para estes filtros"
          )
        ) : (
          <div
            style={{
              height: "250px",
              marginTop: "30px",
              fontSize: "8px",
              textAlign: "center",
              textTransform: "uppercase"
            }}
          >
            carregando lista
            <div
              className="progress"
              style={{
                width: "50%",
                marginLeft: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <div className="indeterminate" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListaDownload;
