import React, { Component } from "react";
import { Chip } from "react-materialize";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Auth from '../login/AuthService';

class FiltrosDownload extends Component {
  constructor(props) {
    super(props);
    let now = new Date()

    this.state = {
      isLoading: true,
      value: "coconut",
      anoDeInicio: now.getFullYear(),
      anoIni: this.props.anoDeInicio,
      anoFim: this.props.anoDeFim,
      mesInicio: this.props.mesDeInicio,
      mesFim: this.props.mesDeFim,
      anoInicio: [],
      anoFinal: [],
      documentosDem: [],
      documentosStatus: "",
      optionAutoComplete:{},
    };

    this.fetchAutocomplete();
    this.onChangeHandlerClientCnpj = this.onChangeHandlerClientCnpj.bind(this);
  }

  fetchAutocomplete() {
    const auth = new Auth();
    auth.getAcessToken(sessionStorage.getItem('hash'), (token)=>{
      if(!token)  this.props.history.push('/home');
      const baseUrl = 'https://empresas-middleware.vidalink.com.br';
      fetch(`${baseUrl}/api/document/list/autocomplete`, {
        method: 'GET',
        headers: {
          "Cache-Control":"max-age=0, no-cache, no-store, must-revalidate",
          "Content-Type": "application/json; charset=utf-8",
          "x-access-token" :token,
        }
      })
      .then(response => response.json())
      .then(resp => {
        let list = {}
        for (const key in resp.data) {
          if(resp.data[key].toLowerCase()!=='all'){
            list[resp.data[key]] = null;
          }
        }
        let obj ={
          data: list,
          limit: Infinity,
          minLength: 1
        }

        this.setState({optionAutoComplete: obj,
          isLoading:false})
      })
    });
  }

  mLabel(e) {
    let mLabel
      switch (e) {
        case 1:
          mLabel = "Janeiro"
        break;
        case 2:
          mLabel = "Fevereiro"
        break;
        case 3:
          mLabel = "Março"
        break;
        case 4:
          mLabel = "Abril"
        break;
        case 5:
          mLabel = "Maio"
        break;
        case 6:
          mLabel = "Junho"
        break;
        case 7:
          mLabel = "Julho"
        break;
        case 8:
          mLabel = "Agosto"
        break;
        case 9:
          mLabel = "Setembro"
        break;
        case 10:
          mLabel = "Outubro"
        break;
        case 11:
          mLabel = "Novembro"
        break;
        case 12:
          mLabel = "Dezembro"
        break;
        default:
      }
      return mLabel
  }

  handleAnoInicio(number) {
    this.props.anoDeInicioCallback(number)
  }

  handleAnoFim(number) {
    this.props.anoDeFimCallback(number)
  }

  onChangeHandlerAnoInicio(number){
    this.handleAnoInicio(number)
    this.setState({ anoIni: number })
    this.setState({ anoFim: null })
    this.setState({mesFim: null})
    this.setState({mesInicio: null})
  }

  onChangeHandlerAnoFim(number){
    this.handleAnoFim(number)
    this.setState({ anoFim: number })
    this.setState({mesFim: null})
    this.setState({mesInicio: null})
  }

  handleMesInicio(number) {
    this.props.mesDeInicioCallback(number)
  }

  handleMesFim(number) {
    this.props.mesDeFimCallback(number)
  }

  onChangeHandlerMesInicio(number){
    this.handleMesInicio(number)
    this.setState({ mesInicio: number })
  }

  onChangeHandlerMesFim(number){
    this.handleMesFim(number)
    this.setState({ mesFim: number })
  }

  handleStatus(value){
    this.props.statusCallback(value);
  }

  handlerTypeDoc(value){
    this.props.typeDocCallBack(value);
  }

  handlerClientCnpj(value){
    this.props.clientCnpjCallBack(value);
  }

  onChangeHandlerStatus(value){
    this.handleStatus(value)
    this.setState({documentosStatus: value})
  }

  onChangeHandlerType(value){
    this.setState({documentosDem: value})
    this.handlerTypeDoc(value)
  }

  onChangeHandlerClientCnpj(value){
    this.handlerClientCnpj(value)
  }

  render() {
    const typeDoc = [
      { label: "Boletos", value: "20" },
      { label: "Demonstrativos", value: "40" },
      { label: "Notas", value: "60" },
      { label: "Outros", value: "80" }
    ];

    const status = [
      { label: "Todos", value: "" },
      { label: "Ativos", value: "1" },
      { label: "Cancelados", value: "0" }
    ];

    let dataAtual = new Date()

    let anoInicio = []
    for(let i=2018; i<=dataAtual.getFullYear()+1; i++){
      if(i <= this.state.anoFim || this.state.anoFim == null ){
      anoInicio.push({ label: i, value: i })
      }else{
      anoInicio.push({ label: i, value: i, disabled: true })
      }
    }

    let anoFinal = []
    for(let i=2018; i<=dataAtual.getFullYear()+1; i++){
      if(i >= this.state.anoIni && i <= (this.state.anoIni+1)){
        anoFinal.push({ label: i, value: i })
      } else {
        anoFinal.push({ label: i, value: i, disabled: true })
      }
    }

    let mesInicial = []
    for(let m=1; m<= 12; m++){
      if(m <= this.state.mesFim || this.state.mesFim == null ){
        mesInicial.push({ label: this.mLabel(m),  value: m })
      }else if(this.state.anoIni == this.state.anoFim || this.state.anoFim == ''){
        mesInicial.push({ label: this.mLabel(m), value: m, disabled: true })
      } else {
        mesInicial.push({ label: this.mLabel(m),  value: m })
      }
    }

    let mesFinal = []
    for(let m=1; m<= 12; m++){
      if(m >= this.state.mesInicio){
        mesFinal.push({ label: this.mLabel(m),  value: m })
      }else if(this.state.anoIni == this.state.anoFim){
        mesFinal.push({ label: this.mLabel(m),  value: m, disabled: true })
      } else {
        mesFinal.push({ label: this.mLabel(m),  value: m })
      }
    }

    return this.state.isLoading ? (
    <div style={{height:'250px', marginTop: '30px', fontSize: '8px', textAlign: 'center', textTransform:'uppercase'}}>
      carregando filtros
    <div
      className="progress"
      style={{
        width: "50%",
        marginLeft: "50%",
        transform: "translateX(-50%)"
      }}
    >
      <div className="indeterminate" />
    </div>
    </div>
    ) : (
      <div>
        {/*primary filters*/}
         <div className="row row--margin-filtros div-flex" style={{justifyContent: 'space-evenly'}}>{/*row--margin-filtros*/}
          {/* <div className="col s12 l1 filtros--paddingP">
          </div> */}
          {/* <div className="col s12 m12 m12--margin-bottom l3 col--filtro-label">
          
          </div> */}
          {/* <div className="input-field icon input--filter col s12 m6 l1">
            <i className="material-icons prefix prefix--filtros">
              calendar_today
            </i>
          </div> */}
          <div className="div-flex col md5"style={{flexDirection: 'column'}}>
          <div>
          <label className="filtros--fontsize-G">Ano de Competência:</label>
          </div>
          <div className="campos">
          
          <div className="input-field ano-field input--filter col s12 m6 l1" style={{position: 'relative'}}>
            {/* <div style={{position: 'absolute', top: '-15px', color: '#333', fontSize: '10.2px'}}>Ano inicial</div> */}
            <Dropdown
              value={this.state.anoIni}
              options={anoInicio}
              onChange={(e) => this.onChangeHandlerAnoInicio(e.value)}
              placeholder="Ano inicial"
            />
          </div>
          <div className="input-field ano-field final input--filter col s12 m6 l1" style={{position: 'relative'}}>
          {/* <div style={{position: 'absolute', top: '-15px', color: '#333', fontSize: '10.2px'}}>Ano final</div> */}
          {this.state.anoIni ? (
            <Dropdown
              value={this.state.anoFim}
              options={anoFinal}
              onChange={(e) => this.onChangeHandlerAnoFim(e.value)}
              placeholder="Ano final"
            />
            ) : (
              <Dropdown
              placeholder="Ano final"
              disabled={true}
            />
            )}
          </div>
          
          </div>
          
            
          </div>
          <div className="div-flex col md5"style={{flexDirection: 'column'}}>
            <div>
            <label className="filtros--fontsize-G">Mês de Competência:</label>
            </div>
          <div className="campos">
          <div className="input-field mes-field input--filter col s12 m6 l1" style={{position: 'relative'}}>
            {/* <div style={{position: 'absolute', top: '-15px', color: '#333', fontSize: '10.2px'}}>Mês inicial</div> */}
            <Dropdown
              style={{border: 'none'}}
              value={this.state.mesInicio}
              options={mesInicial}
              onChange={(e) => this.onChangeHandlerMesInicio(e.value)}
              placeholder="Mês início"
            />
          </div>
          <div className="input-field mes-field final input--filter col s12 m6 l1" style={{position: 'relative'}}>
            {/* <i className="material-icons prefix prefix--filtros">
              calendar_today
            </i> */}
            {/* <div style={{position: 'absolute', top: '-15px', color: '#333', fontSize: '10.2px'}}>Mês final</div> */}
            {this.state.mesInicio ? (
            <Dropdown
              value={this.state.mesFim}
              options={mesFinal}
              onChange={(e) => this.onChangeHandlerMesFim(e.value)}
              placeholder="Mês final"
            />
            ) : (
              <Dropdown
              placeholder="Mês final"
              disabled={true}
            />
            ) }
          </div>
          </div>
          </div>

          <div className="div-flex col md5"style={{flexDirection: 'column'}}>
        <div>
          <label className="filtros--fontsize-G">Tipo de Documento</label>
        </div>
        <div className="campos">
          <div className="input-field input-field--secundario col s12 m6 l3">
            <MultiSelect
              value={this.state.documentosDem}
              placeholder={"Selecione"}
              options={typeDoc}
              onChange={e => this.onChangeHandlerType(e.value)}
            />
          </div>
        </div>
        </div>
        <div className="div-flex col md5"style={{flexDirection: 'column'}}>
        <div>
          <label className="filtros--fontsize-G">Status:</label>
        </div>
        <div className="campos">
        <div className="input-field mes-field input--filter col s12 m6 l1">
            <Dropdown
                value={this.state.documentosStatus}
                options={status}
                onChange={(e) => this.onChangeHandlerStatus(e.value)}
                placeholder="Selecione o status"
              />
          </div>
        </div>
        </div>
          {/* <div className="col s12 m12 m12--margin-bottom l3 mes-competencia col--filtro-label">
            <span className="filtros--fontsize-G">Mês de competência:</span>
          </div>
          <div className="input-field icon input--filter col s12 m6 l1">
            <i className="material-icons prefix prefix--filtros">
              calendar_today
            </i>
          </div> */}
          
        </div>
        {/*secondary filters*/}
        {/* <div className="row row--filtros-secundarios"> */}
        

        
          
          {/* <div className="col s12 l12 tags">
            <Chip
              options={{
                placeholder: "Digite o cliente ou o CNPJ para filtrar",
                secondaryPlaceholder: "Mais filtros",
                autocompleteOptions: this.state.optionAutoComplete,
                onChipAdd: (e) => {
                  this.onChangeHandlerClientCnpj(e[0].M_Chips.chipsData)
                },
                onChipDelete:(e) => {
                  this.onChangeHandlerClientCnpj(e[0].M_Chips.chipsData)
                }
              }}
            />
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}

export default FiltrosDownload;
