import React, { Component } from "react";
import Section from '../template/Section';
import Footer from '../template/Footer';
import HeaderNavbar from '../template/HeaderNavbar';
import Auth from '../login/AuthService';

class Documents extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        sessionStorage.setItem('hash', props.match.params.hash);
      //  this.copyToClipboard = this.copyToClipboard.bind(this)
    }
    
    async componentDidMount() {
        const auth = new Auth();
        auth.getAcessToken(sessionStorage.getItem('hash'), (result)=>{
            if(result)
                this.setState({loading: false});
            else
                this.props.history.push('/')
        }); 
    }

    // copyToClipboard() {
    //     // Create a "hidden" input
    //     var aux = document.createElement("input");
    //     // Assign it the value of the specified element
    //     aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
    //     // Append it to the body
    //     document.body.appendChild(aux);
    //     // Highlight its content
    //     aux.select();
    //     // Copy the highlighted text
    //     document.execCommand("copy");
    //     // Remove it from the body
    //     document.body.removeChild(aux);
    //     //alert("Print screen desabilitado.");
       
    // }

    render() {

        // setInterval(function () {
        //     // this.copyToClipboard();
        //     // Create a "hidden" input
        //     var aux = document.createElement("input");
        //     // Assign it the value of the specified element
        //     aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
        //     // Append it to the body
        //     document.body.appendChild(aux);
        //     // Highlight its content
        //     aux.select();
        //     // Copy the highlighted text
        //     document.execCommand("copy");
        //     // Remove it from the body
        //     document.body.removeChild(aux);
        //     //alert("Print screen desabilitado.");
        //     }, 500);

        // window.addEventListener("keyup", function (e) {
        //     if (e.keyCode == 44 || e.KeyCode == 45) {
        //         //this.copyToClipboard();
        //         // Create a "hidden" input
        //         var aux = document.createElement("input");
        //         // Assign it the value of the specified element
        //         aux.setAttribute("value", "Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.");
        //         // Append it to the body
        //         document.body.appendChild(aux);
        //         // Highlight its content
        //         aux.select();
        //         // Copy the highlighted text
        //         document.execCommand("copy");
        //         // Remove it from the body
        //         document.body.removeChild(aux);
        //         //alert("Print screen desabilitado.");
        //         alert('Você não pode mais dar printscreen. Isto faz parte da nova medida de segurança do sistema.')
        //     }
        // });

        // window.addEventListener("focus", function (e) {
        //     document.getElementsByTagName("BODY")[0].style.display = 'block'
        // });

        // window.addEventListener("blur", function (e) {
        //     document.getElementsByTagName("BODY")[0].style.display = 'none'
        // });

         return this.state.loading ? (
            <div style={{height:'100px', marginTop: '20%', backgroundColor:'white', fontSize: '8px', textAlign: 'center', textTransform:'uppercase'}}>
                carregando aplicação
                <div className="progress" style={{width:"50%", marginLeft:"50%", transform:"translateX(-50%)"}}>
                    <div className="indeterminate" />
                </div>
            </div>
         ) : ( 
            <div>
                {/* <HeaderNavbar /> */}
                <Section />
                {/* <Footer />   */}
            </div>
        );
    }
}

export default Documents;
